/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"muzeum-meteoritu.cz - Kontakty"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-6kipg3 js-anim  --anim3 --anim-s3 --center pt--60" anim={"3"} name={"uvod"} animS={"3"} style={{"paddingBottom":364}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0cc8bf9438e04eeeae742612522856d8_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box title-box--center fs--62 w--900" style={{"maxWidth":700}} content={"Kontakty&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 pb--50 pt--50" anim={"3"} name={"obsah"} animS={"3"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex el--1 flex--center" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"font-weight: bold;\"><br>Muzeum meteoritů s.r.o.<br></span><br>&nbsp;Náměstí Svobody 33\n<br>&nbsp;Frýdek-Místek (Místek)\n<br><br>mail: info@muzeum-meteoritu.cz&nbsp;<br>tel.: +420 703 121 091<br>(po-pá 9-16)<br><br>IČO:&nbsp;21345058<br>Sídlo: Dobrá 957<br>Dobrá&nbsp;73951<br><br><br><span style=\"font-weight: bold;\">Muzeum meteoritu je otevřeno PO-PÁ 9-16h vstup je ZDARMA</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="js-anim  --anim3 --anim-s3 --center" anim={"3"} name={"9vedgfffw75"} animS={"3"} style={{"paddingTop":0,"paddingBottom":5}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Muzeum Meteoritů s.r.o.  © 2024 Vytvořeno s ❤️ k vesmíru"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}